import { Outlet, useSearchParams } from "react-router"
import { useAtomValue } from "jotai"

import ConnectCCP from "@/components/core/ConnectCCP"
import { useLayoutHook } from "@/components/layout/hook"
import Loader from "@/components/layout/Loader"
import { PageLayout } from "@/components/layout/page-layout"
import { ccpModeAtom, modalModeAtom } from "@/helpers/atoms"
import { hasValue } from "@/helpers/typeguards"
import { useUnfinishedManualCallChecker } from "@/hooks/useUnfinishedManualCallChecker"
import ErrorPage from "@/pages/ErrorPage"

export function AppShell() {
  const { agentProfile } = useLayoutHook()
  const ccpMode = useAtomValue(ccpModeAtom)
  const modalMode = useAtomValue(modalModeAtom)
  useUnfinishedManualCallChecker()
  const isDebugMode = useIframeDebugMode()

  return (
    <PageLayout agentProfile={agentProfile} isModalOpen={hasValue(modalMode)}>
      {ccpMode.current === "LOADING" && <Loader isSlow={ccpMode.isSlow} />}
      {ccpMode.current === "INITIALIZED" && <Outlet />}
      {/* If the CCP panel fails to initialize properly (oauth error, exceeded the number of retries, etc.) */}
      {ccpMode.current === "ERROR" && <ErrorPage />}
      <ConnectCCP isHidden={!isDebugMode} />
    </PageLayout>
  )
}

function useIframeDebugMode() {
  const [params] = useSearchParams()

  return params.has("debug")
}
